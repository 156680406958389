
import { BotChecker } from './BotChecker';

export const AreYouARobot = () => {

  return (
    <BotChecker />
  );
};

export default AreYouARobot;
