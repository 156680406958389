import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ReCAPTCHA from 'react-google-recaptcha';

import { useMutation } from '@tanstack/react-query';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, CssVarsProvider, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { AppSkeleton } from 'AppSkeleton';
import { postAPI } from 'lib/APIHelpers';
import { useQueryString } from 'hooks/useQueryString';
import { PageFooter } from 'elements/PageFooter';
import { LocaleProvider, useSurveyTheme } from 'hooks';
import { CONTAINER_WIDTH, DEFAULT_AYT_ORANGE, RECAPTCHA_SITE_PUBLIC_KEY } from 'lib/Enum';
import { LocaleSelector } from 'elements/locale-selector/LocaleSelector';
import { SVGLogo } from 'icons';

const RECAPTCHA_VERSION_3 = 3;
const RECAPTCHA_VERSION_2 = 2;

export const BotChecker = () => {

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [recaptcha_version, setRecaptchaVersion] = useState(RECAPTCHA_VERSION_3);
  const [locales, setLocales] = useState([]);
  const [retryCount, setRetryCount] = useState(0);

  const {
    locale = 'en',
    survey_uuid,
    kiosk_mode
  } = useQueryString();

  const navigate = useNavigate();

  const onSuccess = useCallback(
    ({ short_uid }) => {
      let search = `?locale=${locale}`;

      if (kiosk_mode != undefined)
        search += `&kiosk_mode=${kiosk_mode}`;

      navigate(`/${short_uid}${search}`);
    },
    [navigate, locale, kiosk_mode]
  );

  const onError = useCallback(
    (error) => {
      setRecaptchaVersion(RECAPTCHA_VERSION_2);
      setLocales(error?.response?.data?.error?.survey_locales?.pluck('key'));
    },
    []
  );

  const {
    mutate: sendRecaptchaToken,
    isPending: loading
  } = useMutation({
    mutationFn: (body) => postAPI(
      'api/v2/feedback/robot_check',
      body
    ),
    onSuccess,
    onError,
    throwOnError: (error) => error?.response?.status != 422
  });

  const checkForBots = useCallback(
    async (v2_token) => {
      if (!executeRecaptcha) return;

      let recaptcha_token = null;
      if (recaptcha_version === RECAPTCHA_VERSION_2) {
        recaptcha_token = v2_token;
        //this can be called by the recaptcha V2 component during mounting with no token
        if (!recaptcha_token) return;
      } else {

        try {
          recaptcha_token = await executeRecaptcha('homepage');
        } catch (e) {
          // retries the recaptcha token request
          // this prevent errors from bubbling up to sentry
          if (retryCount < 3) {
            setTimeout(() => checkForBots(), 100);
            setRetryCount(retryCount + 1);
          }
        }
      }
      sendRecaptchaToken({
        recaptcha_token,
        recaptcha_version,
        survey_uuid,
        kiosk_mode,
        locale
      });
    },
    [
      survey_uuid,
      kiosk_mode,
      locale,
      recaptcha_version,
      executeRecaptcha,
      retryCount,
      sendRecaptchaToken
    ]
  );

  const { t } = useTranslation();

  const showV2Recaptcha = recaptcha_version === RECAPTCHA_VERSION_2 && !loading;

  useEffect(
    () => {
      checkForBots();
    },
    [checkForBots]
  );

  const { joyTheme } = useSurveyTheme({ colour: DEFAULT_AYT_ORANGE });

  return (
    <Suspense fallback={<AppSkeleton />}>
      <CssVarsProvider theme={joyTheme}>
        {showV2Recaptcha ? (
          <div className={'app'}>
            <Container
              maxWidth={false}
              sx={{
                px: 3,
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                maxWidth: CONTAINER_WIDTH
              }}
            >
              <Stack
                pt={3}
                mb={{ xs: 4, md: 2 }}
                direction={'row'}
                justifyContent={'flex-end'}
              >
                <LocaleProvider>
                  <LocaleSelector locales={locales} />
                </LocaleProvider>
              </Stack>
              <div className={'app__centered'}>
                <Stack spacing={5} alignItems={'center'}>
                  <SVGLogo size={160} />
                  <Typography fontSize={24} textAlign={'center'}>
                    {t('To continue to the survey, please confirm you are not a robot.')}
                  </Typography>
                  <Typography fontSize={16}>
                    {t('(This helps us keep out spam bots!)')}
                  </Typography>
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_PUBLIC_KEY.V2()}
                    onChange={checkForBots}
                  />
                </Stack>
              </div>
              <PageFooter
                is_kiosk_mode={kiosk_mode}
              />
            </Container>
          </div>
        ) : (
          <AppSkeleton />
        )}
      </CssVarsProvider>
    </Suspense>
  );
};
